<template>
  <b-modal centered hide-header hide-footer id="toggle-internet-subscription" ref="toggle-internet-subscription">
    <div class="text-center font-weight-bolder mb-1">{{ $t(`btn.${isActive ? 'pause' : 'resume'}`) }}</div>
    <div v-if="isActive" class="text-center mb-5">{{ $t('modal.confirm_internet_pause') }}</div>
    <div v-else class="text-center mb-5">{{ $t('modal.confirm_internet_resume') }}</div>
    <div class="d-flex justify-content-center mt-3">
      <b-button variant="light-danger" @click="hideModal">{{ $t('btn.no') }}</b-button>
      <b-button variant="light-success" class="ml-3" @click="submit">{{ $t('btn.yes') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    apartmentId: {default: null},
    status: {type: Number, default: 0}
  },
  computed: {
    isActive() {
      return this.status === 1;
    }
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('apartmentInternetTvStore/TOGGLE_SUBSCRIPTION', {
          apartmentId: this.apartmentId,
          status: this.isActive
        });
        this.$emit('submit');
      } catch(error) {
        console.log(error);
      } finally {
        this.hideModal();
      }
    },
    hideModal() {
      this.$refs['toggle-internet-subscription'].hide();
    }
  }
}
</script>