<template>
  <div>
    <b-modal centered hide-header hide-footer size="xl" id="order-package-modal" ref="order-package-modal">
      <carousel
        v-if="packages.length"
        class="order-package-carousel"
        ref="orderPackageCarousel"
        :perPage="perPage"
        navigationEnabled
        :paginationEnabled="false"
        navigationNextLabel="<i class='fa fa-chevron-right navigation-arrow bg-light bg-hover-primary text-primary text-hover-light'></i>"
        navigationPrevLabel="<i class='fa fa-chevron-left navigation-arrow bg-light bg-hover-primary text-primary text-hover-light'></i>"
      >
        <slide v-for="(packageItem) in packages" :key="packageItem.internetID">
          <div class="py-10 px-0 px-lg-5 text-center">
            <h4 class="font-size-h3 mb-10 text-dark">{{ packageItem.name }}</h4>
            <span class="font-size-h1 font-weight-boldest text-dark">
            <span>{{ packageItem.price.value }}</span>
            <sup class="font-size-h3 font-weight-normal pl-1">{{ packageItem.price.currency_symbol }}</sup>
          </span>
            <h4 class="font-size-h7 small mt-5 text-dark">{{ $t('label.internet') }}</h4>
            <h4 class="font-size-h4 text-dark">{{ packageItem.internetSpeed }} MB/S</h4>
            <h4 class="font-size-h7 small mt-5 text-dark">{{ $t('label.tv') }}</h4>
            <h4 class="font-size-h4 mb-10 text-dark">{{ packageItem.tvChannels }}</h4>
            <div class="mt-5">
              <button
                v-if="checkCancellablePackage(packageItem.internetID)"
                type="button"
                class="btn btn-warning text-uppercase font-weight-bolder px-15 py-3"
                @click="cancelPackage(packageItem.internetID)"
              >
                {{ $t('btn.cancel') }}
              </button>
              <button
                v-else
                type="button"
                class="btn btn-primary text-uppercase font-weight-bolder px-15 py-3"
                :class="{'cursor-not-allowed': isCancellablePackageExists || internet.internetPackage === packageItem.name}"
                :disabled="isCancellablePackageExists || internet.internetPackage === packageItem.name"
                @click="orderPackage(packageItem.internetID, packageItem.tvID)"
              >
                {{ $t('btn.order') }}
              </button>
            </div>
          </div>
        </slide>
      </carousel>
    </b-modal>

    <b-modal centered hide-header hide-footer id="confirm-order-change" ref="confirm-order-change">
      <div class="text-center mb-5">{{ $t('modal.confirm_package_order') }}</div>
      <div class="d-flex justify-content-center mt-3">
        <b-button variant="light-danger" @click="$refs['confirm-order-change'].hide()">
          {{ $t('btn.no') }}
        </b-button>

        <b-button variant="light-success" class="ml-3" @click="confirmPackageOrder">
          {{ $t('btn.yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide
  },
  props: {
    packages: {type: Array, default: () => []},
    internet: {type: Object, default: () => {}}
  },
  data: () => ({
    perPage: 4,
    isCancellablePackageExists: false,
    packageToOrder: {}
  }),
  mounted() {
    window.addEventListener('resize', debounce(this.handleResize, 300));
    this.handleResize();
  },
  computed: {
    requestStatus() {
      return this.internet?.request ? this.internet?.request?.status : null;
    }
  },
  watch: {
    packages: {
      handler(data) {
        if (data.length) {
          setTimeout(() => this.$refs.orderPackageCarousel.onResize(), 1);
        }
      },
      deep: true
    },
    requestStatus(value) {
      if (value !== 0) this.isCancellablePackageExists = false;
    }
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 768) {
        this.perPage = 1;
      } else if (window.innerWidth < 992) {
        this.perPage = 2;
      } else {
        this.perPage = 4;
      }

      if (this.$refs.orderPackageCarousel) {
        this.$refs.orderPackageCarousel.goToPage(0);
      }
    },
    checkCancellablePackage(id) {
      const result = this.requestStatus === 0 && this.internet.request.internet.id === id;
      if (result) {
        this.isCancellablePackageExists = true;
      }
      return result;
    },
    orderPackage(internetId, tvId) {
      this.packageToOrder = { internetId, tvId };
      this.$refs['confirm-order-change'].show();
    },
    confirmPackageOrder() {
      this.$emit('actionConfirmed', this.packageToOrder);
      this.$refs['confirm-order-change'].hide();
      this.hideModal();
    },
    cancelPackage(id) {
      this.$emit('actionCancelled', id);
      this.hideModal();
    },
    hideModal() {
      this.isCancellablePackageExists = false;
      this.$refs['order-package-modal'].hide();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style lang="scss" scoped>
.cursor-not-allowed {
  cursor: not-allowed;
}

.order-package-carousel::v-deep {
  padding: 0 25px;

  .VueCarousel-navigation-prev {
    left: 25px;
  }

  .VueCarousel-navigation-next {
    right: 25px;
  }

  .navigation-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
}
</style>