<template>
  <div>
    <div v-if="data">
      <HeaderData :in-one-row="inOneRow" :details="data"/>
<!--      <div class="separator separator-solid my-5"/>-->
<!--      <DownloadDataForm :apartment-id="apartmentId"/>-->
    </div>
    <div v-else>
      <div class="lead text-center my-5">
        {{ $t('description.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import HeaderData from '@/components/apartments/water/HeaderData';
// import DownloadDataForm from '@/components/apartments/water/DownloadDataForm';

export default {
  components: {
    HeaderData,
    // DownloadDataForm
  },
  props: {
    apartmentId: {
      default: null
    },
    inOneRow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>