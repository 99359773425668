<template>
  <div>
    <DetailsCard :apartment="apartment"/>

    <div class="accordion mt-3" role="tablist">
      <div class="card apartment-card border-left-success h-100 mb-3">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center p-5">
            <div class="d-flex justify-content-center w-75px">
              <span class="fad fa-vacuum font-size-h1"/>
            </div>
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h4 mb-3">
                {{ $t('label.apartment_service') }}
              </span>
              <div v-if="computedServiceDetails">
                <div class="text-muted font-size-sm">{{ $t('label.balance') }}</div>
                <div
                    class="font-weight-bolder font-size-h3"
                    :class="computedServiceDetails.debt === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ `${computedServiceDetails.balance.value} ${computedServiceDetails.balance.currency_symbol}` }}
                </div>
              </div>
            </div>
            <div class="card-toolbar ml-auto">
              <button type="button" class="btn btn-light-success mr-5" @click="goToPay('apartment_service')">
                {{ $t('btn.pay') }}
              </button>
              <span v-b-toggle.services>{{ $t('label.click_to_toggle') }}</span>
            </div>
          </div>
        </div>
        <b-collapse id="services" accordion="my-accordion" role="tabpanel">
          <div class="card-body border-top border-top-success">
            <ServicesCard :apartment-id="apartmentId" :data="apartment"/>
          </div>
        </b-collapse>
      </div>

      <div class="card apartment-card border-left-warning h-100 mb-3">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center p-5">
            <div class="d-flex justify-content-center w-75px">
              <span class="fad fa-bolt font-size-h1"/>
            </div>
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h4 mb-3">{{ $t('label.electricity') }}</span>
              <div v-if="computedElectricityDetails">
                <div class="text-muted font-size-sm">{{ $t('label.balance') }}</div>
                <div
                    class="font-weight-bolder font-size-h3"
                    :class="computedElectricityDetails.debt === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ `${computedElectricityDetails.balance.value} ${computedElectricityDetails.balance.currency_symbol}` }}
                </div>
              </div>
            </div>
            <div class="card-toolbar ml-auto">
              <button type="button" class="btn btn-light-success mr-5" @click="goToPay('electricity')">
                {{ $t('btn.pay') }}
              </button>
              <span v-b-toggle.electricity>{{ $t('label.click_to_toggle') }}</span>
            </div>
          </div>
        </div>
        <b-collapse id="electricity" accordion="my-accordion" role="tabpanel">
          <div class="card-body border-top border-top-warning">
            <ElectricityCard class="mb-3" :apartment-id="apartmentId" :data="apartment" in-one-row/>
          </div>
        </b-collapse>
      </div>

      <div class="card apartment-card border-left-primary h-100 mb-3">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center p-5">
            <div class="d-flex justify-content-center w-75px">
              <span class="fad fa-tint font-size-h1"/>
            </div>
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h4 mb-3">{{ $t('label.water') }}</span>
              <div v-if="computedWaterDetails">
                <div class="text-muted font-size-sm">{{ $t('label.balance') }}</div>
                <div
                    class="font-weight-bolder font-size-h3"
                    :class="computedWaterDetails.debt === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ `${computedWaterDetails.balance.value} ${computedWaterDetails.balance.currency_symbol || ''}` }}
                </div>
              </div>
            </div>
            <div class="card-toolbar ml-auto">
              <button type="button" class="btn btn-light-success mr-5" @click="showPayModal('water')">
                {{ $t('btn.pay') }}
              </button>
              <span v-b-toggle.water>{{ $t('label.click_to_toggle') }}</span>
            </div>
          </div>
        </div>
        <b-collapse id="water" accordion="my-accordion" role="tabpanel">
          <div class="card-body border-top border-top-primary">
            <WaterCard class="mb-3" :apartment-id="apartmentId" :data="computedWaterDetails" in-one-row/>
          </div>
        </b-collapse>
      </div>


      <div class="card apartment-card border-left-primary h-100 mb-3">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center p-5">
            <div class="d-flex justify-content-center w-75px">
              <span class="fad fa-wifi font-size-h1"/>
            </div>
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h4 mb-3">{{ $t('label.internet_tv') }}</span>
              <div v-if="computedInternetTvDetails && computedInternetTvDetails.internetPackage">
                <div class="text-muted font-size-sm">{{ $t('label.balance') }}</div>
                <div
                    class="font-weight-bolder font-size-h3"
                    :class="computedInternetTvDetails.debt === 1 ? 'text-danger' : 'text-success'"
                >
                  {{ `${computedInternetTvDetails.balance.value} ${computedInternetTvDetails.balance.currency_symbol}` }}
                </div>
              </div>
            </div>
            <div v-if="computedInternetTvDetails && computedInternetTvDetails.internetPackage" class="card-toolbar ml-auto">
              <button type="button" class="btn btn-light-success mr-5" @click="goToPay('internet_tv')">
                {{ $t('btn.pay') }}
              </button>
              <span v-b-toggle.internetTv>{{ $t('label.click_to_toggle') }}</span>
            </div>
            <div v-else class="card-toolbar ml-auto">
              <button type="button" class="btn btn-light-success" @click="showOrderPackageModal">
                {{ $t('btn.order') }}
              </button>
            </div>
          </div>
        </div>
        <b-collapse id="internetTv" accordion="my-accordion" role="tabpanel">
          <div class="card-body border-top border-top-primary">
            <InternetTvCard
              class="mb-3"
              :apartment-id="apartmentId"
              :data="apartment"
              in-one-row
              @showPackagesModal="showOrderPackageModal"
              @toggleSubscription="toggleSubscription"
            />
          </div>
        </b-collapse>
      </div>

      <div class="card apartment-card border-left-info h-100">
        <div class="card-header bg-white">
          <div class="d-flex align-items-center p-5">
            <div class="d-flex justify-content-center w-75px">
              <span class="fad fa-door-open font-size-h1"/>
            </div>
            <div class="d-flex flex-column">
              <span class="text-dark font-weight-bold font-size-h4 mb-3">{{ $t('label.doors_history') }}</span>
              <div v-if="lastDoorOpenedAt">
                <div class="text-muted font-size-sm">{{ $t('label.last_opened_at') }}</div>
                <div class="font-weight-bolder font-size-h3 text-dark-75">{{ lastDoorOpenedAt }}</div>
              </div>
            </div>
            <div class="card-toolbar ml-auto">
              <span v-b-toggle.doors-history>{{ $t('label.click_to_toggle') }}</span>
            </div>
          </div>
        </div>
        <b-collapse id="doors-history" accordion="my-accordion" role="tabpanel">
          <div class="card-body border-top border-top-info p-0">
            <DoorsHistoryCard :apartment-id="apartmentId" @lastOpenedAt="lastDoorOpenedAt = $event"/>
          </div>
        </b-collapse>
      </div>
    </div>

    <PayModal :data="computedWaterDetails" @actionConfirmed="goToPay('water')"/>
    <ToggleInternetSubscriptionModal
      :apartmentId="apartmentId"
      :status="apartment ? apartment.internetTv.status : 0"
      @submit="getDetails"
    />
    <OrderPackageModal
      :packages="internetPackages"
      :internet="apartment ? apartment.internetTv : {}"
      @actionConfirmed="orderInternetPackage"
      @actionCancelled="cancelInternetPackage"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DetailsCard from '@/components/apartments/DetailsCard';
import DoorsHistoryCard from '@/components/apartments/DoorsHistoryCard';
import ElectricityCard from '@/components/apartments/ElectricityCard';
import ServicesCard from '@/components/apartments/ServicesCard';
import WaterCard from '@/components/apartments/WaterCard';
import InternetTvCard from '@/components/apartments/InternetTvCard';
import OrderPackageModal from '@/components/elements/modals/OrderPackageModal';
import PayModal from '@/components/elements/modals/PayModal';
import ToggleInternetSubscriptionModal from "@/components/elements/modals/ToggleInternetSubscriptionModal.vue";

export default {
  components: {
    DetailsCard,
    DoorsHistoryCard,
    ElectricityCard,
    ServicesCard,
    WaterCard,
    InternetTvCard,
    OrderPackageModal,
    PayModal,
    ToggleInternetSubscriptionModal
  },
  data: () => ({
    apartmentId: null,
    lastDoorOpenedAt: null
  }),
  beforeMount() {
    this.apartmentId = this.$route.params.id;
    if (this.apartmentId) {
      this.getDetails();
    }
  },
  computed: {
    ...mapGetters({
      apartment: 'apartmentsStore/APARTMENT',
      internetPackages: 'apartmentInternetTvStore/PACKAGES'
    }),
    computedElectricityDetails() {
      return this.apartment ? this.apartment.electricity : null;
    },
    computedServiceDetails() {
      return this.apartment ? this.apartment.service : null;
    },
    computedWaterDetails() {
      return this.apartment ? this.apartment.water : null;
    },
    computedInternetTvDetails() {
      return this.apartment ? this.apartment.internetTv : null;
    }
  },
  methods: {
    async showOrderPackageModal() {
      await this.$store.dispatch('apartmentInternetTvStore/GET_PACKAGES');
      this.$bvModal.show('order-package-modal');
    },
    async toggleSubscription() {
      this.$bvModal.show('toggle-internet-subscription');
    },
    orderInternetPackage({ internetId, tvId }) {
      this.$store.dispatch('apartmentInternetTvStore/ORDER_PACKAGE', {
        apartmentId: this.apartmentId,
        internetID: internetId,
        tvID: tvId
      })
        .then(() => {
          this.$store.dispatch('apartmentsStore/GET_DETAILS', this.apartmentId);
        })
    },
    cancelInternetPackage() {
      this.$store.dispatch('apartmentInternetTvStore/CANCEL_PACKAGE', this.apartmentId)
        .then(() => {
          this.$store.dispatch('apartmentsStore/GET_DETAILS', this.apartmentId);
        })
    },
    showPayModal() {
      this.$bvModal.show('pay-modal');
    },
    goToPay(type) {
      if (type !== 'water') {
        this.$router.push({name: 'pay', params: {apartment_id: this.apartmentId, type: type}});
      } else {
        if (localStorage.getItem("language") === 'ge') {
          window.open(`https://tbcpay.ge/ka/services/wyali/batumis-wyali`, '_blank')
        } else {
          window.open(`https://tbcpay.ge/en/services/wyali/batumis-wyali`, '_blank')
        }
      }
    },
    getDetails() {
      this.$store.dispatch('apartmentsStore/GET_DETAILS', this.apartmentId);
    }
  }
}
</script>

<style lang="scss">
.card.apartment-card {
  border-left: 5px solid;

  .card-header {
    cursor: default !important;
  }
}
</style>
