<template>
  <div class="card rounded-0">
    <div class="card-body p-0 min-h-100px">
      <div v-if="apartment">
        <div class="row">
          <div class="col-md-10 d-flex align-items-center py-2">
            <img class="qr-code cursor-pointer ml-3"
                 :src="`data:image/png;base64,${apartment.qr_code}`"
                 alt=""
                 v-b-modal:qr-url-modal
            >
            <div class="col-md-10 d-flex flex-column">
              <div class="row col-md-12">
                <div class="col-md-3 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.object') }}</span>
                    <span class="font-weight-bolder font-size-h5 text-nowrap">
                    {{ apartment.object }}
                  </span>
                  </div>
                </div>
                <div class="col-md-2 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.block') }}</span>
                    <span class="font-weight-bolder font-size-h5">
                    {{ apartment.block }}
                  </span>
                  </div>
                </div>
                <div class="col-md-4 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.square') }}</span>
                    <span class="font-weight-bolder font-size-h5">
                    {{ apartment.square }}
                  </span>
                  </div>
                </div>
                <div class="col-md-3 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.water_code') }}</span>
                    <span class="font-weight-bolder font-size-h5 text-nowrap">
                    {{ apartment.water.counter_id }}
                  </span>
                  </div>
                </div>
              </div>
              <div class="row col-md-12">
                <div class="col-md-3 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.number') }}</span>
                    <span class="font-weight-bolder font-size-h5">
                    {{ apartment.number }}
                  </span>
                  </div>
                </div>
                <div class="col-md-2 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.floor') }}</span>
                    <span class="font-weight-bolder font-size-h5">
                    {{ apartment.floor }}
                  </span>
                  </div>
                </div>
                <div class="col-md-4 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.cadastre') }}</span>
                    <span class="font-weight-bolder font-size-h5 text-nowrap">
                    {{ apartment.cadastre }}
                  </span>
                  </div>
                </div>
                <div class="col-md-3 my-1">
                  <div class="d-flex flex-column text-dark-75">
                    <span class="text-muted font-weight-bolder font-size-sm">{{ $t('label.ep_code') }}</span>
                    <span class="font-weight-bolder font-size-h5 text-nowrap">
                    {{ apartment.epCode }}
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <div class="col-md-2">
            <div
                class="d-flex justify-content-center align-items-center flex-column h-100 bg-success-o-90 bg-hover-success-o-5 cursor-pointer pt-3 pb-2"
                @click="goToBooking"
            >
              <span class="fal fa-calendar-day font-size-h1"/>
              <span class="mt-3 font-size-h3">{{ $t('btn.book') }}</span>
            </div>
          </div>
        </div>
        <QrUrlModal :url="apartment.qr_url"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import QrUrlModal from '../../components/elements/modals/QrUrlModal';

export default {
  components: {
    QrUrlModal,
  },
  props: {
    apartment: {
      default: null
    }
  },
  methods: {
    goToBooking() {
      this.$router.push({name: 'bookingsList', query: {apartment_id: this.apartment?.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  height: 125px;
  width: 125px;
  background: #dcdcdc;
}
</style>