<template>
  <div class="row mb-5">
    <div class="text-center flex-lg-fill my-1" :class="inOneRow ? 'col-md-4' : 'col-md-4'">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.counter_id') }}</span>
        <span class="font-weight-bolder font-size-h5">
                {{ details.counter_id || '-' }}
              </span>
      </div>
    </div>
    <div class="text-center flex-lg-fill my-1" :class="inOneRow ? 'col-md-4' : 'col-md-4'">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.balance') }}</span>
        <span class="font-weight-bolder font-size-h5"
              :class="details.debt === 1 ? 'text-danger' : 'text-success'">
                {{ `${details.balance.value} ${details.balance.currency_symbol || ''}` }}
              </span>
      </div>
    </div>
<!--    <div class="text-center flex-lg-fill my-1" :class="inOneRow ? 'col-md-3' : 'col-md-4'">-->
<!--      <div class="d-flex flex-column text-dark-75">-->
<!--        <span class="text-muted font-size-sm">{{ $t('label.counter_readings') }}</span>-->
<!--        <span class="font-weight-bolder font-size-h5">-->
<!--                {{ details.last_reading || '-' }}-->
<!--              </span>-->
<!--      </div>-->
<!--    </div>-->
    <div class="text-center flex-lg-fill my-1" :class="inOneRow ? 'col-md-4' : 'col-md-4'">
      <div class="d-flex flex-column text-dark-75">
        <span class="text-muted font-size-sm">{{ $t('label.last_update') }}</span>
        <span class="font-weight-bolder font-size-h5">
               {{ details.last_update || '-' }}
              </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      default: null
    },
    inOneRow: {
      type: Boolean,
      default: false
    }
  }
}
</script>